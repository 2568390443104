import {
  type Duration,
  add,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInSeconds,
  intervalToDuration,
  isValid,
} from "date-fns";

import { safeJoinWithSpace } from "#src/utils/safeJoin.ts";

export function durationInMilliseconds(duration: Duration) {
  const base = new Date(0);
  return differenceInMilliseconds(add(base, duration), base);
}

export function durationInSeconds(duration: Duration) {
  const base = new Date(0);
  return differenceInSeconds(add(base, duration), base);
}

export function maxDuration(durations: Duration[]) {
  return (
    durations
      .slice()
      .sort((a, b) => durationInSeconds(b) - durationInSeconds(a))
      .at(0) ?? { seconds: 0 }
  );
}

export function addDurations(...durations: Duration[]) {
  const start = new Date(0);
  const end = durations.reduce((a, b) => add(a, b), start);
  return intervalToDuration({ start, end });
}

/**
 * Formats a duration as a string in the format Xh Xm
 *
 * @example
 * formatDuration({ hours: 1, minutes: 30 }) // => "1h 30m"
 *
 * @example
 * formatDuration({ hours: 0, minutes: 75 }) // => "1h 15m"
 *
 */
export function formatDuration(duration: Duration): string {
  if (
    (!duration.hours && !duration.minutes) ||
    (duration.hours || 0) < 0 ||
    (duration.minutes || 0) < 0
  ) {
    return "0m";
  }
  const normalizedDate = add(new Date(0), duration);

  if (!isValid(normalizedDate)) {
    return "0m";
  }

  const totalHours = differenceInHours(normalizedDate, new Date(0));
  const remainingMinutes = differenceInMinutes(
    normalizedDate,
    add(new Date(0), { hours: totalHours })
  );

  return (
    safeJoinWithSpace(
      totalHours > 0 ? `${totalHours}h` : null,
      remainingMinutes > 0 ? `${remainingMinutes}m` : null,
      totalHours === 0 && remainingMinutes === 0 ? "0m" : null
    ) || "0m"
  );
}

export function formatDurationFull(duration: Duration) {
  const seconds = duration.seconds ?? 0;
  const minutes = duration.minutes ?? 0;
  const hours = duration.hours ?? 0;
  const days = duration.days ?? 0;
  const months = duration.months ?? 0;
  const years = duration.years ?? 0;

  return safeJoinWithSpace(
    years > 0 ? `${years}y` : null,
    months > 0 ? `${months}mo` : null,
    days > 0 ? `${days}d` : null,
    hours > 0 ? `${hours}h` : null,
    minutes > 0 ? `${minutes}m` : null,
    seconds > 0 ? `${seconds}s` : null
  );
}
